import logo from './Nest-Box-Live-Logo-Large-2-1-300x35.webp';
import playStore from './GetItOnGooglePlay_Badge_Web_color_English.png'
import appStore from './Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917.svg'
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} alt="logo" />
        <p>
        Download the Nest Box Live App
        </p>
        <a href="https://apps.apple.com/us/app/nest-box-live/id1604051432">
          <img src={appStore} width={300} alt="app-store"  style={{ paddingBottom: 10 }}/>
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.nestboxappreactnative">
          <img src={playStore} width={300} alt="play-store" />
        </a>
      </header>
    </div>
  );
}

export default App;
